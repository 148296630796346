let dust = require('dustjs-linkedin');
dust = require('dustjs-helpers');
// Do not re-include our dust-helpers. It adds moment/numbro. If we do, lets break helpers down as needed and replace moment
const { getNumberFormatter } = require('public/js/lib/format-helper');
const NumberFormat = getNumberFormatter();

dust.helpers.priceFormat = function (chunk, context, bodies, params) {
	var raw_price, disp_price, currency_code;

	raw_price = parseFloat(dust.helpers.tap(params.raw_price, chunk, context));
	raw_price = raw_price || 0;

	currency_code = dust.helpers.tap(params.currency_code, chunk, context);
	currency_code = currency_code || '';

	disp_price = NumberFormat.currency(raw_price, currency_code);

	return chunk.write(disp_price);
};

module.exports = window.dust = dust;
