const googleMaps = {};

googleMaps.generateHTML = function (data) {
	const csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n');
	const encodedUri = encodeURI(csvContent);

	return encodedUri;
};

googleMaps.generateIcon = function (label, color) {
	var svgIcon = `
        <svg width="48" height="48" viewBox="0 0 1200 1200" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <style>
        * {
            font-family: Arial, Helvetica, sans-serif;
        }
        </style>
        <g>
        <path d="m617.65 0c233.9 0 412.94 176.3 412.94 410.21v13.316c0 308.25-272.78 691.77-420 776.47h-3.5312c-147.22-84.707-437.65-468.23-437.65-776.47v-13.312c0-233.92 196.69-410.22 430.59-410.22h17.648z" fill="${color}"/>  
        <text x="600" y="600" text-anchor="middle" fill="white" font-size="512" dy=".3em">${label}</text>
        </g>
        </svg>
    `;
	return `data:image/svg+xml;utf-8,${encodeURIComponent(svgIcon)}`;
};

googleMaps.numberToLetter = function (number) {
	let label = '';
	while (number > 0) {
		number--;
		label = String.fromCharCode(65 + (number % 26)) + label;
		number = Math.floor(number / 26);
	}
	return label;
};

module.exports = googleMaps;
